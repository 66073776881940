//import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class LanguageService {
  selected = '';

  constructor(
    private translate: TranslateService,
    //private storage: StorageService
    ) { }

  /**
   * set ar as default
   * !! check storage for default language
   */
  setInitialAppLanguage() {
    this.setLanguage('ar');
    // this.storage.getLang().then(val => {
    //   if (val) { this.setLanguage(val); }
    //   else { this.setLanguage(this.translate.getBrowserLang());}
    // });
  }

  /**
   * set default language in the app
   * !! store it locally
   */
  setLanguage(lng: 'ar' | 'en') {
    this.translate.use(lng);
    this.selected = lng;
    //this.storage.setLang(lng);
  }

  /** !! NOT USED */
  getLanguages() {
    return [
      { text: 'Arabic', value: 'ar' },
      { text: 'English', value: 'en' }
    ];
  }
}
