import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
//import { CanLoginGuard } from './services/can-login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: 'admin', canActivate: [CanLoginGuard], loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)},
  { path: 'game', loadChildren: () => import('./pages/game/game.module').then( m => m.GamePageModule)},
  { path: 'game-team/:gameId', loadChildren: () => import('./pages/game-team/game-team.module').then( m => m.GameTeamPageModule) },
  //{ path: 'game', canActivate: [CanLoginGuard], loadChildren: () => import('./pages/game/game.module').then( m => m.GamePageModule)},
  //{ path: 'team', canActivate: [CanLoginGuard], loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule)},
  //{
  // path: 'game-team/:gameId', canActivate: [CanLoginGuard],
  // loadChildren: () => import('./pages/game-team/game-team.module').then( m => m.GameTeamPageModule)
  // },
  //{ path: 'setting', loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule) },
  //{ path: 'login', loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
